import { z } from 'zod';

import { ExtendedCspSubPropertyInputSchema } from 'types/searchSchemas/ExtendedCspSubPropertyInputSchema';
import { LeaseTypeEnum } from 'types/searchSchemas/SearchFieldSchemas';
import { NumericRangeInputSchema } from '../searchSchemas/NumericRangeInputSchema';

export const CspAvailabilityInputSchema = z.object({
    buildingContigSpace: NumericRangeInputSchema.optional(),
    askingRentMax: NumericRangeInputSchema.optional(),
    areaMax: NumericRangeInputSchema.optional(),
    isAvailable: z.boolean().default(true),
    isFullFloor: z.boolean().optional(),
    leaseType: z.array(LeaseTypeEnum).optional(),
    properties: ExtendedCspSubPropertyInputSchema.optional(),
});

const CspAvailabilityInputSchemaWithOptionalIsAvailable = CspAvailabilityInputSchema.extend({
    isAvailable: CspAvailabilityInputSchema.shape.isAvailable.optional(),
});

export type CspAvailabilityInput = z.infer<
    typeof CspAvailabilityInputSchemaWithOptionalIsAvailable
>;
