import { z } from 'zod';

import { DateRangeInputSchema } from 'types/searchSchemas/DateRangeInputSchema';
import { ExtendedCspSubPropertyInputSchema } from 'types/searchSchemas/ExtendedCspSubPropertyInputSchema';
import { NumericRangeInputSchema } from 'types/searchSchemas/NumericRangeInputSchema';

export const CspSaleInputSchema = z.object({
    closeDate: DateRangeInputSchema.optional(),
    portfolioPricePerArea: NumericRangeInputSchema.optional(),
    salePrice: NumericRangeInputSchema.optional(),
    properties: ExtendedCspSubPropertyInputSchema.optional(),
    buyer: z.array(z.string()).optional(),
    seller: z.array(z.string()).optional(),
});

export type CspSaleInput = z.infer<typeof CspSaleInputSchema>;
