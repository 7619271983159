import Color from '@arcgis/core/Color';
import Geometry from '@arcgis/core/geometry/Geometry';
import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import UniqueValueClass from '@arcgis/core/renderers/support/UniqueValueClass';
import UniqueValueGroup from '@arcgis/core/renderers/support/UniqueValueGroup';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import { produce } from 'immer';
import { flatten, isEmpty } from 'lodash';

import { getSignedUrl, uploadImage } from 'services/cloudinaryService';
import { AppDispatch } from 'store';
import { setHighlightSetProperties } from 'store/highlightSetSlice';
import { startEditMode } from 'store/libraryItemSlice';
import { RightPanelKeys, setActiveRightPanel } from 'store/rightPanelSlice';
import { GoToTarget3DOptions } from 'types/GoToTarget3DOptions';
import {
    BuildingGeometryRef,
    buildingGeometryRefEqual,
    buildingGeometryRefIdOfType,
} from 'types/Layers/BuildingGeometryRef';
import { CsvLayerMetadata } from 'types/Layers/CsvLayerMetadata';
import {
    buildingMetadataFromSaveState,
    OsmBuildingSaveState,
    OsmHighlightSetBuilding,
    OsmHighlightSetBuildingSaveState,
    OsmHighlightSetMetadata,
    OsmHighlightSetPropertyMetadata,
} from 'types/Layers/OsmHighlightSetMetadata';
import endpoints from 'utils/apiClient/endpoints';
import { findMapLayer } from 'utils/esri/findMapLayerUtils';
import { nanoid } from 'utils/idUtils';
import isDefined from 'utils/isDefined';
import { SceneLayerSource } from '../components/HighlighSets/buildingSelectionStyleHelpers';
import LibraryLayerTreeItem, {
    isTreeItemOfType,
    LibraryLayerTreeItemWithMetadata,
    OsmHighlightSetTreeItem,
} from '../types/Layers/LibraryLayerTreeItem';
import {
    isDevPipelineSceneLayer,
    zoomToDevPipelineBuilding,
} from './devPipelineBuildingLayerHelper';
import {
    defaultHighlightSetStyles,
    getHighlightPinIcon,
    HighlightSetStyleProperties,
} from './highlightSetHelper';
import { OSM_PINS_LAYER_ID } from './osmBuildingLayerHelper';
import {
    createOSMUniqueValueGroupsRenderer,
    createUniqueValueClass,
    removeUniqueGroupFromRenderer,
} from './osmStylesHelper';
import { getBuildingCenterByOsmId, queryOverpassAPI } from './overpassApiHelper';
import { isBuildingEditsLayer } from './polygonEditorHelper';
import { createPoint3dSymbolCallout } from './polygonLayerHelper';

const addedPinGraphicIds = {
    _map: new WeakMap<FeatureLayer, Set<number>>(),
    _getOsmIdSet(featureLayer: FeatureLayer) {
        let osmIdSet = this._map.get(featureLayer);
        if (!osmIdSet) {
            this._map.set(featureLayer, (osmIdSet = new Set()));
        }
        return osmIdSet;
    },
    hasOsmId(featureLayer: FeatureLayer, osmId: number): boolean {
        const osmIdSet = this._getOsmIdSet(featureLayer);
        return osmIdSet.has(osmId);
    },
    addOsmIds(featureLayer: FeatureLayer, osmIds: number[]): void {
        const osmIdSet = this._getOsmIdSet(featureLayer);
        for (const osmId of osmIds) {
            osmIdSet.add(osmId);
        }
    },
};

const buildPrefix = (dispatch: AppDispatch, libraryItem: LibraryLayerTreeItem, index?: number) => {
    if (libraryItem.itemType !== 'OsmHighlightSet') {
        console.error('Invalid itemType');
        return null;
    }
    const metadata = libraryItem.metadata;

    const textColor = metadata?.pinColor == 'rgb(255, 255, 255)' ? '#000000' : 'white';
    const content =
        metadata?.pinType == 'numbered' && libraryItem?.ownerId ? (
            <span style={{ color: textColor }}>{index}</span>
        ) : null;
    return (
        <div
            style={{
                flexShrink: 0,
                backgroundColor: metadata?.pinColor,
                display: 'flex',
                alignItems: 'center',
                width: '20px',
                height: '20px',
                borderRadius: '2px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                cursor: 'pointer',
                justifyContent: 'center',
            }}
            onClick={() => {
                if (libraryItem.ownerId || libraryItem.isLeaf) return;
                dispatch(startEditMode({ libraryItem: libraryItem }));
                dispatch(
                    setHighlightSetProperties(libraryItem.metadata as HighlightSetStyleProperties)
                );
                dispatch(setActiveRightPanel(RightPanelKeys.HighlightSet));
            }}
        >
            {content}
        </div>
    );
};

export const createOsmHighlightPinsLayer = () => {
    return new FeatureLayer({
        source: [],
        objectIdField: 'OBJECTID',
        id: OSM_PINS_LAYER_ID,
        fields: [
            {
                name: 'OBJECTID',
                type: 'oid',
            },
            {
                name: 'osmId',
                type: 'long',
            },
            {
                name: 'height',
                type: 'double',
            },
            {
                name: 'highlightItemKey',
                type: 'string',
            },
        ],
        outFields: ['osmId', 'highlightItemKey'],
        elevationInfo: {
            mode: 'relative-to-scene',
        },
        geometryType: 'point',
        renderer: new UniqueValueRenderer({
            field: 'osmId',
            defaultSymbol: createPoint3dSymbolCallout(),
            uniqueValueGroups: [] as UniqueValueGroup[],
        }),
        legendEnabled: false,
        popupEnabled: false,
        spatialReference: {
            wkid: 4326,
        },
    });
};

export function osmBuildingFromOsmGraphic(graphic: Graphic): OsmHighlightSetBuilding {
    const attributes = graphic.attributes;
    const osmId = attributes.OSMID;
    const name = isEmpty(attributes.name) ? osmId : attributes.name;
    return {
        id: nanoid(),
        geometryRef: { layerType: 'osm', id: osmId },
        name,
        height: Math.round(attributes.height ?? 0),
        buildingLevels: attributes.building_levels,
    };
}

export function bbBuildingFromDevPipelineGraphic(graphic: Graphic): OsmHighlightSetBuilding {
    const attributes = graphic.attributes;
    return {
        id: nanoid(),
        geometryRef: { layerType: 'dev-pipeline', id: attributes['BlackbirdId'] },
        name: attributes['BlackbirdId'],
        height: Math.round(attributes['Height'] ?? 0),
    };
}

export function bbBuildingFromBuildingEditsGraphic(graphic: Graphic): OsmHighlightSetBuilding {
    const attributes = graphic.attributes;
    const center = graphic.geometry.extent.center;
    return {
        id: nanoid(),
        geometryRef: { layerType: 'building-edits-layer', id: attributes['BlackbirdId'] },
        name: attributes['BlackbirdId'],
        height: Math.round(attributes['Height'] ?? 0),
        customPosition: {
            latitude: center.latitude,
            longitude: center.longitude,
        },
    };
}

export const getOsmBuildingsFromGraphics = (graphics: Graphic[]): OsmHighlightSetBuilding[] => {
    return graphics.map((graphic: Graphic): OsmHighlightSetBuilding => {
        if (isDevPipelineSceneLayer(graphic.layer.id)) {
            return bbBuildingFromDevPipelineGraphic(graphic);
        } else if (isBuildingEditsLayer(graphic.layer.id)) {
            return bbBuildingFromBuildingEditsGraphic(graphic);
        } else {
            return osmBuildingFromOsmGraphic(graphic);
        }
    });
};

export const createOsmHighlightLibraryItem = (
    buildings: OsmHighlightSetBuilding[],
    styleProperties: HighlightSetStyleProperties,
    title: string,
    csvLayerMetadata?: CsvLayerMetadata
): OsmHighlightSetTreeItem => {
    const key = `OsmHighlightSet--${nanoid()}`;
    const children = createOsmChildren(key, buildings, styleProperties);
    const showTotalTag = !children?.length;
    return {
        id: 0,
        key,
        title,
        itemType: 'OsmHighlightSet',
        children,
        active: true,
        checked: true,
        showTotalTag,
        metadata: { ...styleProperties, csvLayerMetadata, buildings },
        prefixElement: buildPrefix,
    };
};

export const getOsmNodeCollection = (libraryItem: LibraryLayerTreeItem) => {
    return (
        libraryItem.children?.find((item) => item.key.includes('--matched'))?.children ??
        libraryItem.children
    );
};

export function isUnmatchedEntry(buildingMetadata: OsmHighlightSetBuilding) {
    return isDefined(buildingMetadata.extraId) && !isDefined(buildingMetadata.geometryRef);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
export const createOsmHighlightSetFromSavedState = (layer: any): LibraryLayerTreeItem => {
    const { guid, name, active, settings, children } = layer;
    const parsedChildren = typeof children === 'string' ? JSON.parse(children) : children;
    const parsedSettings = typeof settings === 'string' ? JSON.parse(settings) : settings;

    const childStates = [
        ...parsedChildren,
        ...flatten(
            parsedChildren
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
                .filter((item: any) => item.children?.length)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
                .map((item: any) => item.children)
        ),
    ].reduce((result, item) => {
        result[item.key] = item;
        return result;
    }, {});

    const key = guid;
    let metadata: OsmHighlightSetMetadata = parsedSettings || {
        pinColor: defaultHighlightSetStyles().pinColor,
    };
    metadata = migrateBuildingsState(metadata, key, childStates);
    const buildings = metadata.buildings;

    const transformedChildren = createOsmChildren(key, buildings, metadata, childStates);
    const showTotalTag = !transformedChildren?.length;

    return {
        id: 0,
        key,
        title: name,
        showTotalTag: showTotalTag,
        itemType: 'OsmHighlightSet',
        children: transformedChildren,
        checked: active,
        metadata,
        prefixElement: buildPrefix,
    } as LibraryLayerTreeItem;
};

/**
 * Migrate the title from the saved state to the metadata's customName property.
 * This is needed because the the customName is not available in older presentations.
 * This allows the customName property to be used as the single source of truth for
 * any customization to the name
 * This converts old string osmId to number
 *
 * This will also add an id to buildings that don't yet have one.
 *
 * @param metadata The osm highlight sets metadata
 * @param parentKey The key of the osm highlight set
 * @param childStates The customized states of the highlight sets
 * @returns The migrated metadata
 */
function migrateBuildingsState(
    metadata: OsmHighlightSetMetadata,
    parentKey: string,
    childStates: Record<
        string,
        { checked: boolean; title: string; metadata: OsmHighlightSetBuilding }
    >
): OsmHighlightSetMetadata {
    const hasPreviewTable = !!(metadata.pinType === 'numbered' && metadata.buildings?.length);
    return produce(metadata, (metadata) => {
        metadata.buildings = metadata.buildings.map(
            (buildingSaveState: OsmHighlightSetBuildingSaveState) => {
                const building = buildingMetadataFromSaveState(buildingSaveState);
                const osmId = buildingGeometryRefIdOfType(building.geometryRef, 'osm');
                if (!building.id) {
                    if (osmId) {
                        building.id = osmId.toString();
                    } else if (isDefined(building.extraId)) {
                        building.id = `unmatched--${building.extraId}`;
                    }
                }
                if (osmId == undefined || building.customName != null) {
                    return building;
                }
                const title = getBuildingTitle(building);
                const key = `${parentKey}--${building.id}`;
                const savedState = childStates[key];
                if (savedState && title !== savedState.title) {
                    building.customName = buildingNameFromTitle(savedState.title, hasPreviewTable);
                }
                return building;
            }
        );
    });
}

export function buildingNameFromTitle(title: string, hasPreviewTable: boolean) {
    const nameFromPreviewTableFormat = hasPreviewTable && title.match(/^(\d+ - )(.*$)/)?.[2];

    return typeof nameFromPreviewTableFormat === 'string' ? nameFromPreviewTableFormat : title;
}

const getBuildingTitle = (building: OsmHighlightSetBuilding) =>
    building.customName ?? (building.name || building.geometryRef?.id);

export const getHighlightedBuilding = (
    libraryItem: OsmHighlightSetTreeItem,
    geometryRef: BuildingGeometryRef
) => {
    return libraryItem.children?.find((item) =>
        buildingGeometryRefEqual(getGeometryRefFromNode(libraryItem.metadata, item), geometryRef)
    );
};

function createPreviewTable(parentKey: string) {
    return {
        key: `${parentKey}--preview-table`,
        title: 'Preview Table',
        isLeaf: true,
        metadata: { isPreviewTable: true } as OsmHighlightSetPropertyMetadata,
    } as OsmHighlightSetTreeItem;
}

function createOsmChild(
    parentKey: string,
    key: string,
    title: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    style: any
): OsmHighlightSetTreeItem {
    return {
        id: 0,
        key,
        title,
        itemType: 'OsmHighlightSet',
        isLeaf: true,
        active: true,
        checked: true,
        ownerId: parentKey,
        metadata: style,
    };
}

export const createOsmChildren = (
    parentKey: string,
    buildings: OsmHighlightSetBuilding[],
    styleProperties = defaultHighlightSetStyles(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    childStates: Record<string, { checked: boolean; title: string; metadata: any }> = {}
): LibraryLayerTreeItem[] => {
    const { pinType, pinColor } = styleProperties;

    const children: OsmHighlightSetTreeItem[] = [];

    if (styleProperties.pinType === 'numbered' && buildings?.length) {
        const previewTableNode = createPreviewTable(parentKey);

        if (childStates[previewTableNode.key]) {
            const savedState = childStates[previewTableNode.key];
            previewTableNode.checked = savedState.checked;
            previewTableNode.title = savedState.title;
        }

        children.push(previewTableNode);
    }

    buildings.forEach((building: OsmHighlightSetBuilding) => {
        const title = String(getBuildingTitle(building));
        if (building.geometryRef) {
            const key = `${parentKey}--${building.id}`;
            const child = createOsmChild(parentKey, key, title ?? '', {
                pinColor,
                pinType,
            });

            const savedState = childStates[key];
            if (savedState) {
                child.active = savedState.checked;
                child.metadata = savedState.metadata;
                // Note that the title is not set here as we are using the value from
                // the buildings custom name generated by `getBuildingTitle`
            }

            child.prefixElement = buildPrefix;
            children.push(child);
        } else {
            const key = `${parentKey}--unmatched--${building.extraId}`;
            const child = createOsmChild(parentKey, key, title ?? '', {
                pinColor,
                pinType,
            });

            const savedState = childStates[key];
            if (savedState) {
                child.checked = savedState.checked;
                child.title = savedState.title;
                child.metadata = savedState.metadata;
            }

            child.prefixElement = buildPrefix;
            children.push(child);
        }
    });
    return children;
};

export const zoomToOsmHighlightSet = async (
    setGoTo: (target: GoToTarget3DOptions) => void,
    osmId: number
) => {
    const buildings = await getBuildingCenterByOsmId([osmId]);
    if (buildings) {
        const center = buildings[0];
        setGoTo({
            center: [center[0], center[1]],
            zoom: 18,
        });
    }
};
export const zoomToHighlightSet = async (
    setGoTo: (target: GoToTarget3DOptions) => void,
    buildingId: number,
    layerType: SceneLayerSource
) => {
    if (layerType == 'osm') await zoomToOsmHighlightSet(setGoTo, buildingId);
    else if (layerType == 'dev-pipeline') {
        zoomToDevPipelineBuilding(setGoTo, buildingId);
    }
};

export const addHighlightSetPinsOnMap = async (
    key: string,
    pinsLayer: FeatureLayer,
    osmIds: number[],
    styleProperties: OsmHighlightSetMetadata,
    defaultColor: Color
) => {
    if (styleProperties.enabledPinIcon) {
        await addOsmHighlightPins(key, pinsLayer, osmIds, styleProperties, defaultColor);
    } else removeUniqueGroupFromRenderer(pinsLayer, key, defaultColor);
};

export const addOsmHighlightPins = async (
    key: string,
    pinsLayer: FeatureLayer,
    osmIds: number[],
    styleProperties: OsmHighlightSetMetadata,
    defaultColor: Color
) => {
    const { pinType, buildings } = styleProperties;
    try {
        const filtered = osmIds.filter((id) => !addedPinGraphicIds.hasOsmId(pinsLayer, id));
        addedPinGraphicIds.addOsmIds(pinsLayer, osmIds);
        if (filtered.length) {
            await addGraphicsToPinsLayer(pinsLayer, filtered, buildings, key);
        }

        //apply pin styles
        let renderer: UniqueValueRenderer;
        if (pinType === 'generic') {
            renderer = await createGenericIconRenderer(
                pinsLayer,
                osmIds,
                key,
                styleProperties,
                defaultColor
            );
        } else {
            renderer = await createNumberedIconRenderer(pinsLayer, osmIds, key, styleProperties);
        }

        pinsLayer.renderer = createOSMUniqueValueGroupsRenderer(renderer.uniqueValueGroups);
        refreshLayer(pinsLayer);
    } catch (error) {
        console.error('Error adding OSM highlight pins:', error);
    }
};

interface PinAttributes {
    osmId: number;
    height: number | undefined;
    position: { longitude: number; latitude: number };
}

export async function getBuildingPinAttributes(
    metadataBuildings: OsmHighlightSetBuilding[],
    osmIds: number[]
): Promise<PinAttributes[]> {
    const buildings = await queryOverpassAPI(osmIds, 'center');
    const osmBuildingDataMap = new Map(
        buildings?.map((building) => [String(building.id), building]) ?? []
    );

    return metadataBuildings
        .map((building) => {
            const osmId = buildingGeometryRefIdOfType(building.geometryRef, 'osm');
            if (!osmId) {
                return undefined;
            }
            const osmBuilding = osmBuildingDataMap.get(String(osmId));
            let position: { longitude: number; latitude: number };
            if (building.customPosition) {
                position = building.customPosition;
            } else if (osmBuilding?.center) {
                position = { longitude: osmBuilding.center.lon, latitude: osmBuilding.center.lat };
            } else {
                return undefined;
            }

            const heightString = osmBuilding?.tags.height;

            return {
                osmId: osmId,
                height: heightString ? parseInt(heightString) : undefined,
                position,
            };
        })
        .filter(isDefined);
}

export const addGraphicsToPinsLayer = async (
    pinsLayer: FeatureLayer,
    osmIds: number[],
    metadataBuildings: OsmHighlightSetBuilding[],
    key: string
) => {
    const pinAttributes = await getBuildingPinAttributes(metadataBuildings, osmIds);
    const pinGraphicsArray = pinAttributes.map((attributes) => {
        return new Graphic({
            geometry: new Point(attributes.position),
            attributes: {
                osmId: Number(attributes.osmId),
                height: attributes.height,
                highlightItemKey: key,
            },
        });
    });

    await pinsLayer.applyEdits({ addFeatures: pinGraphicsArray });
};

export const createGenericIconRenderer = async (
    pinsLayer: FeatureLayer,
    osmIds: number[],
    key: string,
    styleProperties: OsmHighlightSetMetadata,
    _defaultColor: Color
) => {
    const { pinHeight, pinSize, pinColor, buildings: _buildings } = styleProperties;
    const renderer = pinsLayer.renderer as UniqueValueRenderer;
    const uniqueValueClasses = [] as UniqueValueClass[];
    const icon = await getHighlightPinIcon(styleProperties);
    if (!icon) {
        return renderer;
    }
    for (const id of osmIds) {
        const point3DSymbol = createPoint3dSymbolCallout(icon, pinHeight, pinSize, pinColor);
        uniqueValueClasses.push(createUniqueValueClass([id], point3DSymbol));
    }
    const uniqueGroup = new UniqueValueGroup({
        classes: uniqueValueClasses,
    });
    uniqueGroup.set('key', key);
    renderer.uniqueValueGroups.push(uniqueGroup);
    return renderer;
};

export const createNumberedIconRenderer = async (
    pinsLayer: FeatureLayer,
    osmIds: number[],
    key: string,
    styleProperties: OsmHighlightSetMetadata,
    layerType = 'osm'
) => {
    const { pinHeight, pinSize, pinColor, buildings } = styleProperties;
    const renderer = pinsLayer.renderer as UniqueValueRenderer;
    const uniqueValueClasses = [] as UniqueValueClass[];
    for (const id of osmIds) {
        const index =
            buildings?.findIndex(
                (building) => buildingGeometryRefIdOfType(building.geometryRef, layerType) === id
            ) + 1;
        const icon = await getHighlightPinIcon(styleProperties, index);
        if (!icon) {
            continue;
        }
        const point3DSymbol = createPoint3dSymbolCallout(icon, pinHeight, pinSize, pinColor);
        uniqueValueClasses.push(createUniqueValueClass([id], point3DSymbol));
    }
    const uniqueGroup = new UniqueValueGroup({
        classes: uniqueValueClasses,
    });
    uniqueGroup.set('key', key);
    renderer.uniqueValueGroups.push(uniqueGroup);
    return renderer;
};

export const refreshLayer = (layer: __esri.Layer) => {
    layer.visible = false;
    setTimeout(() => {
        layer.visible = true;
    }, 100);
};

export const createOsmHighlightSetSaveState = (libraryItem: LibraryLayerTreeItemWithMetadata) => {
    const metadata = libraryItem.metadata;
    if (!metadata) return;
    const items = [] as OsmBuildingSaveState[];
    libraryItem.children?.forEach((item) => {
        const { key, title, checked, metadata } = item as LibraryLayerTreeItemWithMetadata;
        items.push({
            key,
            title,
            checked,
            metadata,
        } as OsmBuildingSaveState); // TODO: Handle unmatched buildings.
    });
    return {
        children: items,
        settings: metadata,
        active: items.filter((item) => item.checked).length > 0,
    };
};

export const getHighlightSetBounds = async (
    libraryItem: OsmHighlightSetTreeItem,
    primaryLibraryItemKey?: LibraryLayerTreeItem
) => {
    const { children, isLeaf } = libraryItem;
    let geometryRefs: Array<BuildingGeometryRef | undefined>;
    if (isLeaf) {
        geometryRefs =
            primaryLibraryItemKey && isTreeItemOfType(primaryLibraryItemKey, ['OsmHighlightSet'])
                ? [getGeometryRefFromNode(primaryLibraryItemKey.metadata, libraryItem)]
                : [];
    } else {
        geometryRefs =
            children?.map((child) => getGeometryRefFromNode(libraryItem.metadata, child)) ?? [];
    }

    const osmIds = geometryRefs
        .map((geometryRef) => buildingGeometryRefIdOfType(geometryRef, 'osm'))
        .filter(isDefined);

    if (osmIds.length) {
        return await createBuildingsExtent(osmIds.filter(isDefined));
    }
};

// Define an asynchronous function to retrieve building geometries from OSM using the Overpass API
export const createBuildingsExtent = async (osmIds: number[]) => {
    const points = await getBuildingCenterByOsmId(osmIds);
    return points?.map((point) => new Point({ longitude: point[0], latitude: point[1] })) ?? [];
};

export const recreateHighlightSetChildren = (
    key: string,
    buildings: OsmHighlightSetBuilding[],
    styleProperties: HighlightSetStyleProperties
) => {
    return createOsmChildren(key, buildings, styleProperties);
};
export const uploadToCloudinary = async (
    imageBlob: File,
    folder: string = 'presentations/highlightSets'
) => {
    const url = await getSignedUrl({
        folder,
    });

    const cloudinaryResult = await uploadImage(url, imageBlob);
    return cloudinaryResult['public_id'];
};

export const saveCloudinaryImageId = async (cloudinaryImageId: string, name: string) => {
    const saveResponse = await endpoints.customIcons.save.post({
        fetchOptions: {
            body: JSON.stringify({ cloudinaryImageId, name }),
        },
    });
    if (!saveResponse.ok) {
        throw new Error('Failed to save annotation collection');
    }
    const id = await saveResponse.json();
    return id;
};

export const getCustomIcons = async () => {
    const response = await endpoints.customIcons.all.get();
    return await response.json();
};

export const deleteCustomIcons = async (iconId: number | string) => {
    return await endpoints.customIcons.icon.delete({
        templateValues: { iconId },
    });
};

export const getGeometryFromFeatureLayerByKey = (
    layerId: string,
    key: string
): Geometry | undefined => {
    const layer = findMapLayer(layerId) as FeatureLayer;
    if (!layer) return;
    const graphic = layer.source.find((item) => item.getAttribute('libraryItemKey') == key);
    return graphic?.geometry;
};

export const zoomToFeatureLayerByKey = async (
    layerId: string,
    key: string,
    setGoTo: (options: GoToTarget3DOptions) => void
) => {
    const geometry = getGeometryFromFeatureLayerByKey(layerId, key);
    if (!geometry) return;
    setGoTo({
        target: geometry,
        zoom: 16,
    });
};

export const getAllChildrenKeys = (
    libraryItem: LibraryLayerTreeItem,
    keys: string[] = [],
    checkedFilter = true
): string[] => {
    if (checkedFilter) keys.push(libraryItem.key);

    if (libraryItem.children) {
        for (const item of libraryItem.children) {
            getAllChildrenKeys(item, keys);
        }
    }

    return keys;
};

export const buildingMetadataForItemKey = (
    itemKey: string,
    osmHighlightMetadata: OsmHighlightSetMetadata
): OsmHighlightSetBuilding | undefined => {
    let isMatch: (building: OsmHighlightSetBuilding) => boolean;
    if (itemKey.includes('--unmatched--')) {
        const extraId = itemKey.split('--').pop() ?? '';
        isMatch = (building) => building.extraId === extraId;
    } else {
        const buildingId = getBuildingIdFromNode(itemKey);
        isMatch = (building) => building.id === buildingId;
    }

    return osmHighlightMetadata.buildings.find(isMatch);
};

export function allHighlightMetadataForKey(
    itemKey: string,
    primaryItem: LibraryLayerTreeItem | undefined
): {
    primaryMetadata: OsmHighlightSetMetadata | undefined;
    buildingMetadata: OsmHighlightSetBuilding | undefined;
} {
    const primaryMetadata =
        primaryItem?.itemType === 'OsmHighlightSet' ? primaryItem.metadata : undefined;
    const buildingMetadata = primaryMetadata
        ? buildingMetadataForItemKey(itemKey, primaryMetadata)
        : undefined;
    return { primaryMetadata, buildingMetadata };
}

export function getGeometryRefFromNode(
    metadata: OsmHighlightSetMetadata,
    node: LibraryLayerTreeItem
): BuildingGeometryRef | undefined;
export function getGeometryRefFromNode(
    metadata: OsmHighlightSetMetadata,
    nodeKey: string
): BuildingGeometryRef | undefined;
export function getGeometryRefFromNode(
    metadata: OsmHighlightSetMetadata,
    node: LibraryLayerTreeItem | string
): BuildingGeometryRef | undefined {
    return buildingMetadataForItemKey(typeof node === 'string' ? node : node.key, metadata)
        ?.geometryRef;
}

export function getBuildingIdFromNode(node: LibraryLayerTreeItem): string;
export function getBuildingIdFromNode(nodeKey: string): string;
export function getBuildingIdFromNode(node: LibraryLayerTreeItem | string): string {
    const nodeKey = typeof node === 'string' ? node : node.key;
    const segments = nodeKey.split('--');
    return segments.slice(2).join('--');
}
