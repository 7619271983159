import { message } from '@jll/react-ui-components';
import { z } from 'zod';

import { SlideItemSaveState, SlideSettings } from 'components/Slides/SlideTypes';
import SharedState, { SharedStateString } from 'enums/SharedState';
import { FeatureStates } from 'store/featuresSlice';
import { LibraryItemSaveState } from 'types/Layers/LibraryItemSaveState';
import endpoints from 'utils/apiClient/endpoints';

export interface MarketSettings {
    amenitiesSearch: string;
    availabilityViews: string;
    cameraSetting: string;
    demographics: string;
    id: number;
    leaseViews: number;
    marketId: number;
    marketViewOptions: string;
    presentationId: number;
    propertyProviderId: number;
    propertyReport: string;
    propertyViews: number;
    retailSettings: string;
    submarketStatistics: string;
    yopParentSnapshotView: string;
    visibilities: string;
    libraryItems: LibraryItemSaveState[];
}

export interface UserPermission {
    accessType: number;
    createdBy: string;
    description: string;
    email: string;
    expiresAt: Date;
    id: number;
    userId: number;
    userPrincipalName: string;
}

export interface GroupPermission {
    accessType: number;
    createdBy: string;
    createdDate: Date;
    groupName: string;
    id: number;
    modifiedBy: string;
    modifiedDate: Date;
    presentationId: number;
}

export interface PresentationSecurity {
    accessType: number;
    isPublished: boolean;
    groupPermissions: GroupPermission[];
    userPermissions: UserPermission[];
}

export interface Presentation {
    id: number;
    presentationName: string;

    marketId: number;
    markets: number[];
    marketSettings: MarketSettings[];

    dataProvider?: string;

    accessType: number;
    isPublished: boolean;
    groupPermissions: GroupPermission[];
    userPermissions: UserPermission[];

    createdBy: string;
    createdDate?: Date;
    modifiedBy: string;
    modifiedDate?: Date;

    slides: SlideItemSaveState[];
    annotation: string;
    cameraSettings: string;
    mapFeatures?: FeatureStates;
    propertyComments: [];
    propertyInventorySettings: string;
    propertyReport: string;
    searchColorSettings: string;
    slideSettings: SlideSettings;
    clientVersion: number;
}

export function canEditPresentation(presentation: Presentation) {
    return presentation.accessType > 1;
}

export interface PresentationCompare {
    id: number;
    presentationName: string;
    libraryItems: LibraryItemSaveState[];
    slides: SlideItemSaveState[];
    mapFeatures?: FeatureStates;
}

export interface PresentationSummary {
    id: number;
    presentationName: string;
    marketName: string;
    createdBy: string;
    createdDate: Date;
    modifiedBy: string;
    modifiedDate: Date;
    sharedBy: string;
    sharedDate: Date;
    accessType: number;
    accessedDate?: Date;
    imageType: string;
    isPublished: boolean;
    isShared: boolean;
    isSharedWithMe: boolean;
    isFavorite: boolean;
    ownerId: number;
    pursuitId?: number;
    isDefaultPresentation: boolean;
    clientVersion: number;
}

export interface PresentationSummaryResponse {
    id: number;
    accessType: number;
    accessedDate?: string;
    imageType: string;
    isPublished: boolean;
    isShared: boolean;
    isSharedWithMe: boolean;
    isFavorite: boolean;
    marketName: string;
    ownerId: number;
    presentationName: string;
    pursuitId: number;
    sharedBy: string;
    createdBy: string;
    createdDate: string;
    modifiedBy: string;
    modifiedDate: string;
    sharedDate: string;
    isDefaultPresentation: boolean;
}

export interface PresentationQueryResultResponse {
    presentationSummaries: PresentationSummaryResponse[];
    total: number;
}

export interface PresentationQueryResult {
    presentationSummaries: PresentationSummary[];
    total: number;
}

export interface PresentationSearchOptions {
    searchQuery?: string;
    sharedState?: SharedState | SharedStateString;
    orderBy?: string;
    marketIds?: string;
    offset?: number;
    limit?: number;
    arcgisOnly?: boolean;
}

export interface PresentationSharePayload {
    id: number;
    level1Access: string;
    level2Access: string;
    presentationName: string;
    updatedBy: string;
}

export async function getPresentationSecurity(id: number): Promise<PresentationSecurity> {
    const response = await endpoints.presentations.security.get({
        templateValues: {
            presentationId: id,
        },
    });
    return await response.json();
}

export async function getPresentation(id: number): Promise<Presentation> {
    const response = await endpoints.presentations.open.get({
        templateValues: {
            presentationId: id,
        },
        queryParameters: {
            clientVersion: '1',
        },
    });

    return await response.json();
}

export async function searchPresentations(
    options: PresentationSearchOptions
): Promise<PresentationQueryResult> {
    const response = await endpoints.presentations.search.get({
        queryParameters: options as Record<string, string | number | boolean>,
    });
    const results: PresentationQueryResultResponse = await response.json();

    return {
        presentationSummaries: results.presentationSummaries.map((summary) => {
            return {
                ...summary,
                sharedDate: new Date(summary.sharedDate),
                createdDate: new Date(summary.createdDate),
                accessedDate: summary.accessedDate ? new Date(summary.accessedDate) : undefined,
                modifiedDate: new Date(summary.modifiedDate),
            };
        }) as PresentationSummary[],
        total: results.total,
    };
}

export async function getDefaultPresentation(marketId: number) {
    try {
        const response = await endpoints.presentations.defaultPresentation.get({
            queryParameters: {
                marketId,
            },
        });

        return await response.json();
    } catch (error) {
        return null;
    }
}

export function setAsFeaturedPresentation(presentationId: number) {
    return endpoints.presentations.featured.post({
        templateValues: {
            presentationId: presentationId,
        },
    });
}

export function removeFromFeaturedPresentations(presentationId: number) {
    return endpoints.presentations.featured.delete({
        templateValues: {
            presentationId: presentationId,
        },
    });
}

export const ShareInfoDtoShareUser = z.object({
    id: z.number(),
    accessType: z.number(),
    description: z.string(),
    createdBy: z.string(),
    userId: z.number(),
    email: z.string(),
    displayName: z.string(),
    isClientUser: z.boolean(),
    expiresAt: z.string().optional().nullable(),
    userPrincipalName: z.string(),
});
export type ShareInfoDtoShareUser = z.infer<typeof ShareInfoDtoShareUser>;

export const ShareInfoDtoShareStatistics = z.object({
    lastAccessTime: z.string().optional().nullable(),
    accessCount: z.number().optional().nullable(),
});

export type ShareInfoDtoShareStatistics = z.infer<typeof ShareInfoDtoShareStatistics>;

export const ShareInfoDtoShareEntry = z.object({
    user: ShareInfoDtoShareUser,
    statistics: ShareInfoDtoShareStatistics.nullable().optional(),
});
export type ShareInfoDtoShareEntry = z.infer<typeof ShareInfoDtoShareEntry>;

export function shareInfoDtoShareEntryIsExpired(entry: ShareInfoDtoShareEntry): boolean {
    return entry.user.expiresAt ? Date.parse(entry.user.expiresAt) <= +new Date() : false;
}

export const ShareInfoDto = z.object({
    shares: z.array(ShareInfoDtoShareEntry),
});

export type ShareInfoDto = z.infer<typeof ShareInfoDto>;

export async function getPresentationShareStats(id: number): Promise<ShareInfoDto> {
    const value = await endpoints.presentations.shareStatistics.get({
        templateValues: { presentationId: id },
    });

    return ShareInfoDto.parse(await value.json());
}

export async function savePresentation(presentation: Presentation): Promise<number> {
    const result = await endpoints.presentations.save.post({
        templateValues: {
            presentationId: presentation.id,
        },
        queryParameters: {
            clientVersion: '1',
        },
        fetchOptions: {
            body: JSON.stringify(presentation),
        },
    });

    if (!result.ok) {
        throw new Error('Failed to save presentation');
    }

    return Number(await result.text());
}

export async function getPresentationsByMarketCodes(
    marketCodes: number[]
): Promise<Record<number, string>> {
    const result = await endpoints.presentations.byMarketCodes.get({
        queryParameters: {
            marketCodes: marketCodes.join(','),
        },
    });

    return await result.json();
}

export async function getCspPresentations(): Promise<Record<number, string>> {
    const result = await endpoints.presentations.byCsp.get();

    return await result.json();
}

export const exportPresentation = async (presentationId: number, format: 'PDF' | 'Powerpoint') => {
    try {
        await endpoints.staticPresentation.generate.post({
            templateValues: { presentationId, format },
        });
        message.success('Your report will be sent to you via email once it’s ready.');
    } catch (e) {
        message.error('Something went wrong, please try again.');
    }
};

export async function publishPresentation(presentationId: number): Promise<number> {
    const result = await endpoints.presentations.publish.post({
        templateValues: {
            presentationId: presentationId,
        },
    });

    if (!result.ok) {
        throw new Error('Failed to publish presentation');
    }

    return Number(await result.text());
}

export async function revokePublish(presentationId: number): Promise<number> {
    const result = await endpoints.presentations.revokePublish.post({
        templateValues: {
            presentationId: presentationId,
        },
    });

    if (!result.ok) {
        throw new Error('Failed to revoke published presentation');
    }

    return Number(await result.text());
}

export async function shareWithUsers(presentationId: number, payload: PresentationSharePayload) {
    const result = await endpoints.presentations.shareWithUsers.post({
        templateValues: {
            presentationId: presentationId,
        },
        fetchOptions: {
            body: JSON.stringify(payload),
        },
    });

    if (!result.ok) {
        throw new Error('Failed to share presentation');
    }

    return Number(await result.text());
}

export const getPresentationClientVersion = async (presentationId: number) => {
    const response = await endpoints.presentations.clientVersion.get({
        templateValues: { presentationId },
    });
    return await response.text();
};

export const getDataProvider = async (presentationId: number) => {
    const response = await endpoints.presentations.dataProvider.get({
        templateValues: { presentationId },
    });
    return await response.text();
};
